import { useCallback, useRef } from 'react';
import { TestimonialCard, Chevron } from "../../../components";
import { useTailwindBreakpoint } from '../../../hooks';

interface Testimonial {
    stars: number;
    review: string;
    reviewerName: string;
}

const testimonialTestData : Testimonial[] = [
    {
        stars: 5,
        review: '5th St. Slush Kings took our party from just a normal get together to an event people are still talking about weeks later. Can\'t wait to book again!',
        reviewerName: 'Sean'
    }, 
    {
        stars: 5,
        review: 'I recently attended an event that featured 5th St. Slush Kings. The drinks were an absolute hit and I definitely will use them in the future!',
        reviewerName: 'CJ'
    }
]

const Testimonials = () => {
    const myRef = useRef<HTMLDivElement>(null);
    const breakpoint = useTailwindBreakpoint();

    const getScrollInterval = (direction: string) => {
        const directionMultiplier = direction === 'left' ? -1 : 1
        let scrollInterval = null;
        if (breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') {
            scrollInterval = (window.innerWidth - ((window.innerWidth - 300) / 2)) * directionMultiplier
        } else if ( breakpoint === 'lg' ) {
            scrollInterval =  (window.innerWidth - ((window.innerWidth - 600) / 3)) * directionMultiplier
        } else {
            scrollInterval = (window.innerWidth - ((window.innerWidth - 900) / 4)) * directionMultiplier
        }
        
        return scrollInterval;
    }

    const scrollLeft = useCallback(() => {
        myRef.current?.scrollBy({ left: getScrollInterval('left'), top: 0, behavior: "smooth"});
    }, [breakpoint])

    const scrollRight = useCallback(() => {
        myRef.current?.scrollBy({ left: getScrollInterval('right'), top: 0, behavior: "smooth"});
    }, [breakpoint]);

    return (
        <div className='col-start-1 col-span-3'>
            <div className='col-start-2 col-span-1 text-center font-merriweather text-xl xs:text-2xl md:text-4xl font-base md:font-normal mb-[2vh] text-sky-950'>Customer Testimonials</div>
            <div ref={myRef} className='flex gap-testimonial-sm md:gap-testimonial-md lg:gap-testimonial-lg items-center col-start-1 col-span-3 bg-rose-100 p-14 overflow-auto pl-testimonial-sm pr-testimonial-sm md:pl-testimonial-md md:pr-testimonial-md lg:pl-testimonial-lg lg:pr-testimonial-lg no-scrollbar'>
                <Chevron 
                        direction='left'
                        clickHandler={() => scrollLeft}
                />
                { testimonialTestData.map(({ stars, review, reviewerName }: Testimonial) =>  {
                        return (
                            <TestimonialCard
                                key={ reviewerName }
                                stars={ stars }
                                review={ review }
                                reviewerName={ reviewerName }
                            />
                        )
                    })}
                <Chevron 
                    direction='right'
                    clickHandler={() => scrollRight}
                />
            </div>
        </div>
    );
};

export default Testimonials;