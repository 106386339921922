interface DividerProps {
    topMargin?: string;
    bottomMargin?: string;
}

const Divider = ({ topMargin, bottomMargin}: DividerProps) => {
    return (
        <div className={`col-start-1 col-span-3 ml-10 mr-10 bg-white h-[.5px] ${ topMargin } ${ bottomMargin }`}></div>
    );
};

export default Divider;