import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { post } from 'aws-amplify/api';

import CheckoutForm from "./CheckoutForm";
import DynamicReceipt from "../DynamicReceipt";
import { useCheckoutContext } from "../../../context";
import { PaymentIntent } from "../../../context/Types";
import { CircularProgress } from "@mui/material";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`);

const createPaymentIntent = async ({ packageType, flavorAddOn, cupAddOn, refillAddOn } : any) =>  {
  try {
    const restOperation = post({ 
      apiName: 'slushkingsapi',
      path: '/createPaymentIntent',
      options: {
        body: {
            machineType: packageType,
            flavorAddOn,
            cupAddOn, 
            refillAddOn
        }
      }
    });
    const { body } = await restOperation.response;
    const response = await body.json();

    return response;
  } catch (e : any) {
    console.log(e)
  }
};

const updatePaymentIntent = async ({ paymentIntentId, packageType, flavorAddOn, cupAddOn, refillAddOn } : any) => {
    try {
        const restOperation = post({ 
          apiName: 'slushkingsapi',
          path: '/updatePaymentIntent',
          options: {
            body: {
                paymentIntentId,
                machineType: packageType,
                flavorAddOn,
                cupAddOn, 
                refillAddOn
            }
          }
        });
        const { body } = await restOperation.response;
        const response = await body.json();
    
        return response;
      } catch (e : any) {
        console.log(e)
      }
}


const Payment = () => {
  // add new state for if add ons have been selected. Then we will pass those here to our createPayment intent call
  // add updatePaymentIntent lambda for when people move back to change their checkout. add that to the useEffect
  const { 
    packageType,
    flavorAddOn,
    cupAddOn,
    refillAddOn,
    paymentIntent,
    setPaymentIntent
} = useCheckoutContext();
const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!paymentIntent) {
        console.log('Creating payment intent');
        createPaymentIntent({ packageType, flavorAddOn, cupAddOn, refillAddOn })
        .then((data : any) => {
            const paymentIntentData : PaymentIntent = {
                paymentIntentId: data.paymentIntent.id,
                clientSecret: data.paymentIntent.client_secret
            }
            setPaymentIntent(paymentIntentData)
            setIsLoading(false);
        });
    } else {
        console.log('Update payment intent');
        const { paymentIntentId } = paymentIntent;
        updatePaymentIntent({ paymentIntentId, packageType, flavorAddOn, cupAddOn, refillAddOn })
        setIsLoading(false);
    }
  }, [packageType, cupAddOn, flavorAddOn, refillAddOn]);

  const appearance = {
    theme: 'stripe',
  };
  const elementOptions : any = {
    clientSecret: paymentIntent?.clientSecret,
    appearance,
    loader: 'always',
  };

  return (
    <div className='flex flex-col gap-5 items-center justify-center col-start-1 col-span-5 row-start-2 sm:col-start-2 sm:col-span-3 lg:col-start-4 lg:col-span-2 lg:row-start-1 lg:row-span-2 xl:col-start-3 border-solid border-slate-400 border-[1px] lg:border-l-0 lg:rounded-xl lg:rounded-l-none p-10'>
      { isLoading ? <CircularProgress /> : 
         paymentIntent && 
          <Elements key={paymentIntent.paymentIntentId} options={elementOptions} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
      }
    </div>
  );
}

const PaymentStep = () => {
    return (
        <>
            <Payment />
            <DynamicReceipt />
        </>
    )
}

export default PaymentStep;