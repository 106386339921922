import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CheckoutProvider } from './context'
import './input.css';

import { 
  Home,
  AboutUs,
  Merch,
  Products,
  ProductDetails,
  PackageBuilder,
  PrivacyPolicy,
} from './pages';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig, {
  API: {
    REST: {
      headers: async () => {
        return { 'X-Api-Key': `${process.env.REACT_APP_SLUSH_KINGS_API_KEY}` };
      }
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />
  },
  {
    path: "/merchandise",
    element: <Merch />
  }, 
  {
    path: "/products",
    element: <Products />
  },
  {
    path: "/products/:productId",
    element: <ProductDetails />
  },
  {
    path: '/package-builder',
    element: <PackageBuilder />
  },
  {
    path: '/confirmation',
    element: <div>Confirmation</div>
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CheckoutProvider>
        <RouterProvider router={router} />
      </CheckoutProvider>
    </LocalizationProvider>
  </React.StrictMode>
);
