import Kings from './pictures/SlushTeam.jpeg'

const Kingspic = () => {
    return (
        <div id="Team pic" className="relative col-start-1 col-span-3 lg:col-start-2 lg:col-span-1 items-center shadow-xl border-solid border-[.5px] border-slate-500 overflow-hidden">
          <img
          className='w-full'
          src = {Kings}      
          />
        </div>
    );
}

export default Kingspic;