import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { useCheckoutContext } from "../../context";
import { Dayjs } from 'dayjs';
import { PACKAGE_TYPE } from "./ProductDetails";
import SingleTankPackage from '../../assets/SingleTankPackage.jpg';
import DoubleTankPackage from '../../assets/DoubleTankPackage.png';


type FormData = {
    deliveryZip: string,
    date: Dayjs | null
}

const packageSummary = {
    "single-tank": {
        title: 'Single Tank Package',
        description: 'Our single tank package is perfect for a few very thirsty friends or for a more casual mid-sized event. If you have a mid-sized event where guests will enjoy multiple beverages then the double tank package would be a better bet.',
        basePrice: '200',
    },
    "double-tank": {
        title: 'Double Tank Package',
        description: 'Our double tank package is perfect for mid-sized to large events. If you have a mid-sized event where guests will enjoy multiple beverages then this is the package for you.',
        basePrice: '350',
    }
}

const PackageForm = ({ packageType }: any) => {
    // const { setDeliveryZip, setDeliveryDate } = useCheckoutContext();
    // const navigate = useNavigate();
    const { title, description, basePrice } = packageType === PACKAGE_TYPE.SINGLE_TANK ? packageSummary["single-tank"] : packageSummary["double-tank"]; 
    const packageImg = packageType === PACKAGE_TYPE.SINGLE_TANK ? SingleTankPackage : DoubleTankPackage
    
    // const { control, handleSubmit } = useForm<FormData>({
    //     defaultValues: {
    //       deliveryZip: '',
    //       date: null
    //     }
    //   });

    // const onSubmit = ({ deliveryZip, date }: FormData) => {
    //     setDeliveryZip(deliveryZip);
    //     setDeliveryDate(date!.toDate())
    //     navigate(`/package-builder/`)
    // }

    return (
        <div className='col-start-1 col-span-8 lg:col-start-2 lg:col-span-6 justify-center flex flex-col md:flex-row border-solid border-[1px] bg-slate-200 shadow-lg'>
            <div id='package-container' className='flex flex-col gap-4 justify-start items-center w-full md:w-[60%] pl-[2vh] pr-[2vh] pb-[4vh] bg-s'>
                <div className='font-merriweather text-lg md:text-xl lg:text-2xl font-semibold mb-[1vh] mt-[4vh]'>{ title }</div>
                <div className='bg-sky-950 p-[2px] w-3/4 xl:w-1/2'>
                    <img src={packageImg} className='border-solid border-[1px] border-orange-100' alt='placeholder'></img>
                </div>
            </div>
            <div id='form-container' className='flex flex-col justify-start items-center w-[100%] md:w-[40%] bg-orange-50 p-2'>
                <div className='font-merriweather text-xl text-center mt-[4vh]'>Product Summary</div>
                <div className='font-merriweather text-base text-center mt-[3vh]'>{ description }</div>
                <div className='font-merriweather text-base text-center mt-[3vh]'>Our 24 hour rentals include machine, service table, mix, cups, and delivery/pickup. Base package price is <b className="text-xl text-cyan-700 font-merriweather">${ basePrice }</b></div>
                <div className='font-merriweather text-base text-center mt-[3vh]'>See the package specs below to see additional information on machine freeze times, tank capacity, and flavor selection</div>
                <div className='font-merriweather text-base text-center mt-[3vh]'>Call us at <b className="text-xl text-cyan-700 font-merriweather">703-832-9067</b> to book!</div>
                {/* <form className='flex flex-col gap-6 justify-center items-center w-full mt-[5vh] h-full' onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="deliveryZip"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => <TextField variant='outlined' label='Delivery Zip' className='w-3/4 bg-white rounded-md' {...field} />}
                    />
                    <Controller
                        control={control}
                        name="date"
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    label="Date"
                                    className='w-3/4 bg-white rounded-md'
                                    value={field.value}
                                    inputRef={field.ref}
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                />
                            );
                        }}
                    />
                    <Button 
                        label={'Begin Building Package'}
                        color='bg-red-700'
                        textColor='text-white'
                        height='h-12'
                        callBack={() => {}}
                        styles="w-3/4 mt-auto mb-[3vh]"
                        type='submit'
                    />
                </form> */}
            </div>
        </div>
    );
};

export default PackageForm;