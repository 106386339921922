import { useNavigate } from "react-router-dom";
import { Button } from "../../../components";
import { Package } from "../../../types/types";

const ProductCard = ({ 
    packageName,
    packageId,
    imgSrc,
    machineType,
    drinkCount,
    startingPrice
}: Package) => {
    const navigate = useNavigate();

    const Card = () => {
        return (
            <div className='bg-sky-950 p-4 md:w-2/5'>
                <div className='flex flex-col justify-center items-center gap-6 border-solid border-[.5px] border-orange-200 bg-slate-100'>
                    <div className='text-2xl font-merriweather font-normal mt-[4vh] text-sky-950'>{ packageName }</div>
                    <img className='w-[400px] aspect-3/4' alt='package-visual' src={ imgSrc } />
                    <div className='flex flex-wrap justify-center items-start gap-y-4 w-full lg:w-3/4'>
                        <div className='w-1/2  text-start font-merriweather pl-3 text-xs sm:text-base text-sky-950'>{ `• ${ machineType } Bunn machine` }</div>
                        <div className='w-1/2  text-start font-merriweather pl-3 text-xs sm:text-base text-sky-950'>{ `• Full machine serves ${ drinkCount } (8oz) drinks` }</div>
                        <div className='w-1/2  text-start pl-3 '>{ <p className='text-xs sm:text-base text-sky-950 font-merriweather '>• Base package starting at: <b className='text-cyan-700 font-merriweather text-lg'>${ startingPrice }</b></p> }</div>
                        <div className='w-1/2  text-start font-merriweather pl-3 text-xs sm:text-base text-sky-950'>• Includes cups and mix</div>
                    </div>
                    <Button 
                        label={'See more details'}
                        color='bg-cyan-700'
                        textColor='text-white'
                        height='h-12'
                        callBack={() => navigate(`/products/${ packageId }`)}
                        styles="mb-5"
                    />
                </div>
            </div>
        )
    }

    return (
        <Card />
    )
}

export default ProductCard;