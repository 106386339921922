import { NavLink } from "react-router-dom";
import SocialButton from "../buttons/SocialButton";
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

const pages = [
    {
        pageName: 'Home',
        pageRoute: '/',
    },
    {
        pageName: 'Products',
        pageRoute: '/products',
    },
    {
        pageName: 'About Us',
        pageRoute: '/about-us',
    }
];

const NavBar = () => {
    const navItems = pages.map(({ pageName, pageRoute }) => {

        return ( 
            <div key={pageName} className="flex justify start sm:justify-center items-center w-1/5">
                <NavLink
                    to={pageRoute}
                    style={({ isActive }) => {
                        return {
                          color: isActive ? "orange" : "",
                        };
                      }}
                    className={({ isActive }) =>
                        [
                            isActive ? "active" : "",
                        ].join(" ") + " font-merriweather text-xs sm:text-sm md:text-base text-white font-light hover:text-rose-400"
                    }
                >
                    {pageName}
                </NavLink>
            </div>
        );
    });

    return (
        <>
            <nav className='fixed top-0 start-0 z-50 w-full flex justify-between bg-sky-900 h-9 shadow-lg'>
                <div className='flex gap-4 justify-center items-center ml-6'>
                    <SocialButton icon={faFacebook} redirectUrl="https://www.facebook.com" size="lg"/>
                    <SocialButton icon={faInstagram} redirectUrl="https://www.instagram.com/5thstslushkings/" size="lg"/>
                </div>
                <div className='font-merriweather text-white text-base text-center mr-4'>Call <b className="text-xl text-amber-200 font-merriweather ml-2 mr-2">703-832-9067</b> to book!</div>
            </nav>
            <nav className="fixed top-9 start-0 z-50 w-full grid grid-cols-8 grid-rows-1 gap-0 bg-sky-950 shadow-lg">
                <div className="flex justify-left items-center col-start-1 col-span-3 row-start-1 h-14">
                    <p className=" font-merriweather text-sm sm:text-base md:text-xl text-white font-semibold ml-5">5th St. Slush Kings</p>
                </div>
                <ul className="flex justify-end items-center gap-2 col-start-2 col-span-7 row-start-1 h-16 sm:col-start-3 sm:col-span-4 sm:gap-5 sm:justify-center">
                    { navItems }
                </ul>
            </nav> 
        </>   
    );
}

export default NavBar;