import { faInstagram, faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";

import LogoTransparent from '../../assets/LogoTransparent.png'
import SocialButton from '../buttons/SocialButton'
import { Link } from "react-router-dom";
import Divider from "../Divider";

const BrandingInfo = () => {
    return (
        <div className='flex flex-col items-center w-full md:w-1/3 p-6 gap-2'>
            <img src={ LogoTransparent } className='w-2/3' />
            <div id='social-buttons' className='flex justify-center gap-6 items-center w-full md:w-2/3'>
                <SocialButton icon={faFacebook} redirectUrl="https://www.facebook.com" size="2xl"/>
                <SocialButton icon={faInstagram} redirectUrl="https://www.instagram.com/5thstslushkings/" size="2xl"/>
            </div>
        </div>
    )
}

const SiteMap = () => {
    return (
        <div className='flex justify-start items-start w-full md:w-2/3 h-full mt-4 sm:mt-0'>
            <div className='flex flex-col w-2/5 md:w-1/4 items-end justify-center h-full md:h-3/4 md:items-start gap-4'>
                <div className='flex justify-start w-3/5'>
                    <Link className='font-merriweather text-white text-start hover:font-bold hover:underline' to={'/'}>Home</Link>
                </div>
                <div className='flex justify-start w-3/5'>
                    <Link className='font-merriweather text-white hover:font-bold hover:underline' to={'/products'}>Packages</Link>
                </div>
                <div className='flex justify-start w-3/5'>
                    <Link className='font-merriweather text-white hover:font-bold hover:underline' to={'/about-us'}>About us</Link>
                </div>
            </div>
        </div>
    )
}

const DocumentLinks = () => {
    return (
        <div id='document-links' className="flex flex-col gap-4 w-full bg-sky-950 mb-4 mt-10">
            <Divider />
            <div className='flex ml-10 mr-10 gap-4'>
                <Link to={'/website-terms'} className='font-merriweather text-white text-sm text-opacity-80 hover:font-bold hover:underline'>Website Terms</Link>
                <div className='font-merriweather text-white text-sm text-opacity-80'>|</div>
                <Link to={'/privacy-policy'} className='font-merriweather text-white text-sm text-opacity-80 hover:font-bold hover:underline'>Privacy Policy</Link>
            </div>
            <div className='font-merriweather text-white text-sm ml-10 mr-10 opacity-80'>&copy; 2024 5th St. Slush Kings, LLC. All Rights Reserved</div>
        </div>
    );
}

const Footer = () => {
    return (
        <div className='bg-sky-950 col-start-1 col-span-3 mt-20 flex flex-col'>
            <div id='main-content-container' className='flex flex-col md:flex-row items-center'>
                <BrandingInfo />
                <SiteMap />
            </div>
            <DocumentLinks />
        </div>
    );
};

export default Footer;