/** @type {import('tailwindcss').Config} */
const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  future: {
    hoverOnlyWhenSupported: true,
  },
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
],
  theme: {
    screens: {
      'xs': '380px',
      ...defaultTheme.screens,
    },
    extend: {
      keyframes: {
        // This is currently configured for 5 seconds per slide w/ 1 second slide transition
        slide: {
            '0%': { left: 0 },
            '27.77%': { left: 0 },
            '33.32%': { left: '-100%' },
            '61.09%': { left: '-100%' },
            '66.64%': { left: '-200%' },
            '94.41%': { left: '-200%' },
            '100%': { left: '-300%' }
        },
        textSlideIn: {
          from: {
            opacity: "0",
            transform: "translateY(-10px)",
          },
          to: {
            opacity: "1",
            transform: "translateY(0)",
          },
        },
      },
      animation: {
        slide: 'slide 18s linear infinite',
        textslidein300: "textSlideIn 1.5s ease 300ms forwards",
        textslidein500: "textSlideIn 1.5s ease 900ms forwards",
        textslidein700: "textSlideIn 1.5s ease 1500ms forwards", 
      },
      colors: {
        paleGreen: '#7B8D8E',
        palePink: '#F2DEDE'
      },
      fontFamily: {
        rubik: ["Rubik", "sans-serif"],
        merriweather: ["Merriweather"],
        courgette: ["Courgette"],
        pacifico: ["Pacifico"],
        playfair: ["Playfair+display"]
      },
      gridTemplateColumns: {
        'home': '1fr 4fr 1fr',
        'product-details': '2fr 4fr 4fr 4fr 4fr 4fr 4fr 2fr',
        'package-builder': '1fr 4fr 1fr 6fr 1fr'
      },
      gridTemplateRows: {
        'package-builder': 'auto auto'
      },
      aspectRatio: {
        '3/4': '3 / 4',
        '/3/5': '3 / 5',
        '4/3': '4 / 3',
      },
      width: {
        'package': 'min(82vw, 350px)',
        'testimonial': '300px'
      },
      gap: {
        'mobile-package': 'calc(100vw - min(82vw, 350px))',
        'desk-package': 'max(10px, 5vw)',
        'testimonial-sm': 'calc((100vw - 300px) / 2)',
        'testimonial-md': 'calc((100vw - 600px) / 3)',
        'testimonial-lg': 'calc((100vw - 900px) / 4)'
      },
      padding: {
        'package': 'calc((100vw - min(82vw, 350px)) / 2)',
        'testimonial-sm': 'calc((100vw - 300px) / 2)',
        'testimonial-md': 'calc((100vw - 600px) / 3)',
        'testimonial-lg': 'calc((100vw - 900px) / 4)'
      }
    },
  },
  plugins: [],
}
