import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import LogoTransparent from '../../../assets/LogoTransparent.png';

const HeaderImage = () => {
    return (
        <div className='flex justify-center lg:justify-end bg-sky-950 w-full lg:w-2/5 h-[300px] sm:h-[450px] lg:h-[600px]'>
            <div className='h-full bg-orange-100'>
                <img src={LogoTransparent} className="h-full" />
            </div>
        </div>
    )
}

export const Header = () => {
    const navigate = useNavigate();

    const explorePackageCallback = () => {
        navigate("/products");
    }

    const aboutUsCallback = () => {
        navigate("/about-us");
    }
    return (
        <div className="col-start-1 col-span-3 flex flex-col lg:flex-row">
            <HeaderImage />
            <div className='flex flex-col w-full lg:w-3/5 lg:h-[600px] items-center gap-4 md:gap-12 lg:gap-20 bg-sky-950 justify-start pl-16 pr-16 pt-6 lg:pt-16'>
                <div className='font-merriweather text-lg sm:text-3xl lg:text-5xl text-white text-center leading-loose font-extralight animate-textslidein300 opacity-0'>
                    Making <text className='italic font-semibold text-amber-200'>this</text> summer the one you'll never forget
                </div>
                <div className='font-merriweather text-md sm:text-lg lg:text-2xl text-amber-200 text-center leading-loose font-extralight animate-textslidein500 opacity-0'>
                    Now servicing the Northern Virginia area!
                </div>
                <div className='flex w-full gap-10 justify-center items-center mt-auto mb-10 lg:mb-36 animate-textslidein700 opacity-0'>
                    <Button
                        label='Explore Packages'
                        color='bg-rose-400'
                        textColor='text-white'
                        height='h-12'
                        styles='w-1/2 sm:w-1/3 md:w-1/4'
                        callBack={explorePackageCallback}
                    />
                    <Button 
                        label='About Us'
                        color='bg-rose-400'
                        textColor='text-white'
                        height='h-12'
                        styles='w-1/2 sm:w-1/3 md:w-1/4'
                        callBack={aboutUsCallback}
                    />
                </div>
            </div>
            
        </div>
    )
}