import { 
    createContext,
    useContext,
    useReducer,
    FC
} from "react";
import { PACKAGE_TYPE, CheckoutState, FlavorItem, CupSelection, CUP_STYLES, PaymentIntent } from "./Types";
import { reducer, RequestTypes } from "./CheckoutReducer";

type CheckoutProviderProps = {
    children?: React.ReactNode
}

interface dispatchActionMethods {
    setPackageType: (arg: PACKAGE_TYPE) => void;
    setDeliveryZip: (arg: string) => void;
    setDeliveryDate: (arg: Date) => void;
    addFlavorSelection: (arg: FlavorItem) => void;
    removeFlavorSelection: (arg: FlavorItem) => void;
    clearFlavorSelection: () => void;
    setCupSelection: (arg: CupSelection) => void;
    clearCupSelection: () => void;
    setPaymentIntent: (arg: PaymentIntent) => void;
    setFlavorAddOn: (arg: Boolean) => void;
    setCupAddOn: (arg: Boolean) => void;
    setRefillAddOn: (arg: Boolean) => void;
    setReceiptOpen: (arg: Boolean) => void;
    setShowDropdown: (arg: Boolean) => void;
}

const initialState : CheckoutState = {
    packageType: PACKAGE_TYPE.single,
    deliveryZip: '',
    deliveryDate: new Date(),
    flavorSelection: [],
    cupSelection: { cupSelectionId: CUP_STYLES.standard, name: 'Standard Cups' },
    paymentIntent: null,
    flavorAddOn: false,
    cupAddOn: false,
    refillAddOn: false,
    receiptOpen: false,
    showDropdown: false,
};

const initialDispatchActions = {
    setPackageType: () => {},
    setDeliveryZip: () => {},
    setDeliveryDate: () => {},
    addFlavorSelection: () => {},
    removeFlavorSelection: () => {},
    clearFlavorSelection: () => {},
    setCupSelection: () => {},
    clearCupSelection: () => {},
    setPaymentIntent: () => {},
    setFlavorAddOn: () => {},
    setCupAddOn: () => {},
    setRefillAddOn: () => {},
    setReceiptOpen: () => {},
    setShowDropdown: () => {},
};

const CheckoutContext = createContext<CheckoutState & dispatchActionMethods>({
    ...initialState,
    ...initialDispatchActions
});


/*
    This provider provides not only our context to the rest of the app, but also helper methods so that we don't have to have dispatch all over the components.
    We can simply call the helper methods that we have provided here to abstract it away from the components
*/
export const CheckoutProvider : FC<CheckoutProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);


    /*
        We pass these functions to the components via the useCheckoutContext. All state and functions can be accessed by destructuring the properties you need in any
        component you want, no matter how deep in the DOM they are. Look at the example below:
        
        ex.  const { deliveryZip, setDeliveryZip} = useCheckoutContext()
    */
    const setPackageType = (packageType: PACKAGE_TYPE) => {
        dispatch({
            type: RequestTypes.setPackageType,
            payload: {
                packageType: packageType
            }
        })
    }

    const setDeliveryZip = (zip: string) => {
        dispatch({
            type: RequestTypes.setDeliveryZip,
            payload: {
                deliveryZip: zip
            }
        })
    }

    const setDeliveryDate = (date: Date) => {
        dispatch({
            type: RequestTypes.setDeliveryDate,
            payload: {
                deliveryDate: date
            }
        })
    }

    const addFlavorSelection = (flavorItem: FlavorItem) => {
        dispatch({
            type: RequestTypes.addFlavorSelection,
            payload: {
                flavorItem: flavorItem
            }
        })
    }

    const removeFlavorSelection = (flavorItem: FlavorItem) => {
        dispatch({
            type: RequestTypes.removeFlavorSelection,
            payload: {
                flavorItem: flavorItem
            }
        })
    }

    const clearFlavorSelection = () => {
        dispatch({
            type: RequestTypes.clearFlavorSelection,
            payload: {
                flavorItems: []
            }
        })
    }

    const setCupSelection = (cupSelection: CupSelection) => {
        dispatch({
            type: RequestTypes.setCupSelection,
            payload: {
                cupSelection: cupSelection
            }
        })
    }

    const clearCupSelection = () => {
        dispatch({
            type: RequestTypes.clearCupSelection,
            payload: {
                cupSelection: { cupSelectionId: CUP_STYLES.standard, name: 'Standard Cups' }
            }
        })
    }

    const setPaymentIntent = (paymentIntent : PaymentIntent) => {
        dispatch({
            type: RequestTypes.setPaymentIntent,
            payload: {
                paymentIntent: paymentIntent
            }
        })
    }

    const setFlavorAddOn = (flavorAddOn: Boolean) => {
        dispatch({
            type: RequestTypes.setFlavorAddOn,
            payload: {
                flavorAddOn: flavorAddOn
            }
        })
    }

    const setCupAddOn = (cupAddOn: Boolean) => {
        dispatch({
            type: RequestTypes.setCupAddOn,
            payload: {
                cupAddOn: cupAddOn
            }
        })
    }

    const setRefillAddOn = (refillAddOn: Boolean) => {
        dispatch({
            type: RequestTypes.setRefillAddOn,
            payload: {
                refillAddOn: refillAddOn
            }
        })
    }

    const setReceiptOpen = (open : Boolean) => {
        dispatch({
            type: RequestTypes.setReceiptOpen,
            payload: {
                open: open
            }
        })
    }

    const setShowDropdown = (show: Boolean) => {
        dispatch({
            type: RequestTypes.setShowDropdown,
            payload: {
                show: show
            }
        })
    }

    const dispatchActionMethods = {
        setPackageType,
        setDeliveryZip,
        setDeliveryDate,
        addFlavorSelection,
        removeFlavorSelection,
        clearFlavorSelection,
        setCupSelection,
        clearCupSelection,
        setPaymentIntent,
        setFlavorAddOn,
        setCupAddOn,
        setRefillAddOn,
        setReceiptOpen,
        setShowDropdown,
    }

    return (
        <CheckoutContext.Provider value={{ ...state, ...dispatchActionMethods}}>
            { children }
        </CheckoutContext.Provider>
    )
}

export const useCheckoutContext = () => {
    return useContext(CheckoutContext);
}

