import PackageSpec from "./PackageSpec";
import { PACKAGE_TYPE } from "./ProductDetails";

const packageSpecs = {
   singleTank: [
        {
            title: "Machine Specs",
            specs: [
                'Commercial, single tank Donper machine featuring one 3-gallon tank allowing, perfect for smaller crowds',
                'Full machine serves about 50 8oz drinks on a single fill',
                'Typical freeze time is about 2 hours depending on temperature conditions'
            ]
        },
        {
            title: "Flavor Options",
            specs: [
                'Base package include 1 selection from our flavor options. This will be enough to fully fill the machine once',
                'Our current flavor offerings are: lime, strawberry, mango, blue raspberry, peach, and pino colada',
                'A full refill, including a second flavor selection, can be purchase for an additional $35'
            ]
        },
        {
            title: "Other included items",
            specs: [
                'This package also includes a quality, stainless-steel, service table rated for 500lbs',
                'This package also includes 50 (8oz) cups. If a refill flavor is added to the order, it will also come with another set of cups'
            ]
        },
        {
            title: "Delivery",
            specs: [
                'Both delivery and pickup are included in the base package price.',
                'Delivery and Pickup windows are 2 hours, although we aim arrive toward the beginning of the window to allow more time to freeze.'
            ]
        },
    ],
    doubleTank: [
        {
            title: "Machine Specs",
            specs: [
                'Commercial, dual tank Bunn machine featuring two 3-gallon tanks allowing for two separate drink offerings',
                'Full machine serves about 100 8oz drinks on a single fill',
                'Typical freeze time is about 2 hours depending on temperature conditions'
            ]
        },
        {
            title: "Flavor Options",
            specs: [
                'Base package includes 2 selections from our flavor options. This will be enough to fully fill the machine once',
                'Our current flavor offerings are: lime, strawberry, mango, blue raspberry, peach, and pino colada',
                'A full refill, including a second set of flavor selections, can be purchase for an additional $50'
            ]
        },
        {
            title: "Other included items",
            specs: [
                'This package also includes a quality, stainless-steel, service table rated for 500lbs',
                'This package also includes 100 (8oz) cups. If refill flavors are added to the order, it will also come with another set of cups'
            ]
        },
        {
            title: "Delivery",
            specs: [
                'Both delivery and pickup are included in the base package price.',
                'Delivery and Pickup windows are 2 hours. although we aim arrive toward the beginning of the window to allow more time to freeze.'
            ]
        },
    ],

};


const PackageSpecsDropdown = ({ packageType }: { packageType: PACKAGE_TYPE }) => {
    const packageInfo = packageType === PACKAGE_TYPE.SINGLE_TANK ? packageSpecs.singleTank : packageSpecs.doubleTank;
    return (
        <div className='col-start-1 col-span-8 md:col-start-2 md:col-span-6 flex flex-col gap-3 text-center bg-cyan-700 border-solid border-[1px] border-slate-400 pt-[2vh] pb-[2vh] pl-[2vh] pr-[2vh]'>
            { packageInfo.map(({ title, specs }: any) => <PackageSpec title={title} specs={specs}/>)}
        </div>
    )
};

export default PackageSpecsDropdown;