import { ReactNode } from "react";

interface ButtonProps {
    label: ReactNode;
    color: string;
    textColor: string;
    height: string;
    callBack: Function;
    styles?: string;
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
    id?: string
};

const Button = ({ label, color, textColor, height, callBack, styles, type, disabled, id}: ButtonProps) => {
    return (
        <button 
            className={`
                flex
                justify-center
                items-center
                text-sm
                sm:text-base
                p-3
                rounded-lg
                bg-opacity-95
                border-solid
                border-[.5px]
                border-slate-500
                ${ color }
                ${ height }
                ${ textColor }
                ${ styles }
                 cursor-pointer
                hover:bg-opacity-100
                hover:shadow-xl
                hover:border-[1px]
                hover:border-white
            `}
            type={type}
            onClick={() => callBack()}
            disabled={disabled}
            id={id}
        >
            { label }
        </button>
    );
}

export default Button;