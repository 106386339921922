import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

type SocialButtonProps = {
    icon: IconDefinition;
    redirectUrl: string;
    size: SizeProp;
}

const SocialButton = ({ icon, redirectUrl, size}: SocialButtonProps) => {
    return (
        <a 
            className='flex justify-center items-center aspect-square rounded-full bg-red-300 hover:border-white hover:border-2 p-1'
            href={redirectUrl}
        >
            <FontAwesomeIcon icon={ icon } size={size} inverse/>
        </a>
    )
}

export default SocialButton;