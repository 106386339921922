type SpecProps = {
    title: string,
    specs: string[]
};

const PackageSpec = ({ title, specs }: SpecProps) => {
    return (
        <div className='bg-slate-200 border-solid border-[1px] border-slate-300 p-3'>
            <div className='flex flex-col gap-3 rounded-md bg-white shadow-md pb-5'>
                <div className='flex items-start ml-5 mt-5 mb-2 font-merriweather text-xl font-semibold text-rose-400'>{ title }</div>
                { specs.map((spec) => (
                    <div className='flex flex-col justify-start items-start ml-5'>
                        <div key={ spec } className='text-start font-merriweather text-md text-sky-950 flex items-start justify-start'>• { spec }</div>
                    </div>
                ))
                }
            </div>
        </div>
    );
}

export default PackageSpec