import { useLayoutEffect } from 'react';
import { NavBar, Footer } from '../../components';

const PrivacyPolicyDocument = () => {
    return (
        <div className='flex flex-col justify-center items-center md:items-start gap-8 md:gap-12 col-start-1 col-span-3 md:col-start-2 md:col-span-1 m-6 mt-12'>
            <div>
                <h1 className='font-merriweather text-xl md:text-2xl text-sky-950 text-center mb-6 md:mb-10'>Privacy Policy</h1>
                <p className='font-merriweather text-center md:text-start leading-6 italic'>This Privacy Policy ("Policy") outlines how [Your Company Name] ("Company," "we," "us," or "our") collects, uses, discloses, and protects the information gathered through your use of the [Your Company Name] website (the "Website"). By accessing or using the Website, you agree to be bound by this Policy.</p>
            </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>1. Information We Collect</h1>
                <p className='font-merriweather text-center md:text-start'>We may collect personal information that you voluntarily provide to us, such as your name, email address, and any other information you choose to share when contacting us through the Website.</p>
            </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>2. Use of Information</h1>
                <p className='font-merriweather text-center md:text-start'>We may use the information we collect to communicate with you, respond to your inquiries, provide customer support, and improve our products and services.</p>
            </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>3. Disclosure of Information</h1>
                <p className='font-merriweather text-center md:text-start'>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or as necessary to provide services to you.</p>
           </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>4. Cookies and Tracking Technologies</h1>
                <p className='font-merriweather text-center md:text-start'>We may use cookies and similar tracking technologies to enhance your browsing experience and gather information about how you use the Website. You can choose to disable cookies in your web browser settings, but this may affect the functionality of the Website.</p>
           </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>5. Third-Party Links</h1>
                <p className='font-merriweather text-center md:text-start'>The Website may contain links to third-party websites or services that are not operated by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
          </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>6. Data Security</h1>
                <p className='font-merriweather text-center md:text-start'>We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
           </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>7. Children's Privacy</h1>
                <p className='font-merriweather text-center md:text-start'>The Website is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</p>
            </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>8. Changes to the Policy</h1>
                <p className='font-merriweather text-center md:text-start'>We reserve the right to modify or update this Policy at any time, without prior notice, by posting the revised version on the Website. Your continued use of the Website after any such changes constitutes acceptance of the revised Policy.</p>
            </div>
            <div>
                <h1 className='font-merriweather text-lg md:text-xl text-rose-400 text-center md:text-start mb-2 md:mb-4'>9. Contact Information</h1>
                <p className='font-merriweather text-center md:text-start'>If you have any questions or concerns about this Policy, please contact us at</p>
            </div> 
        </div>
    );
};

const PrivacyPolicy = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
      <>
        <NavBar />
        <div className="grid gap-y-20 mt-24 grid-cols-home">
          <PrivacyPolicyDocument />
          <Footer />
        </div>
      </>
  )
};

export default PrivacyPolicy;