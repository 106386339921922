import { Footer, NavBar } from "../../components";
import PackageSpecsDropdown from "./PackageSpecsDropdown";
import PackageForm from './PackageForm';
import { useEffect, useLayoutEffect } from "react";
import { useCheckoutContext } from "../../context";
import { useNavigate, useParams } from "react-router-dom";

export enum PACKAGE_TYPE  {
    'SINGLE_TANK' = 'single-tank',
    'DOUBLE_TANK' = 'double-tank'
}

const ProductDetails = () => {
    const { clearFlavorSelection } = useCheckoutContext();
    const { productId }: any = useParams();
    const navigate = useNavigate();

    if (!productId || !Object.values(PACKAGE_TYPE).includes(productId)) {
        navigate('/');
    }
    const product = productId === PACKAGE_TYPE.SINGLE_TANK ? PACKAGE_TYPE.SINGLE_TANK : PACKAGE_TYPE.DOUBLE_TANK;

    useEffect(() => {
        clearFlavorSelection();
    }, []);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar />
            <div className='grid gap-y-3 mt-28 grid-cols-product-details m-1'>
                <PackageForm packageType={ product } />
                <PackageSpecsDropdown packageType={ product }/>
            </div>
            <Footer />
        </>
    );
}

export default ProductDetails;