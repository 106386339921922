import { 
    PACKAGE_TYPE,
    CheckoutState,
    FlavorItem,
    CupSelection,
    PaymentIntent
} from "./Types";
import { filter } from "lodash";

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
      ? {
          type: Key;
        }
      : {
          type: Key;
          payload: M[Key];
        }
};

export enum RequestTypes {
    setPackageType = 'SET_PACKAGE_TYPE',
    setDeliveryZip = 'SET_DELIVERY_ZIP',
    setDeliveryDate = 'SET_DELIVERY_DATE',
    addFlavorSelection = 'SET_FLAVOR_SELECTION',
    removeFlavorSelection = 'REMOVE_FLAVOR_SELECTION',
    clearFlavorSelection = 'CLEAR_FLAVOR_SELECTION',
    setCupSelection = 'SET_CUP_SELECTION',
    clearCupSelection = 'CLEAR_CUP_SELECTION',
    setPaymentIntent = 'SET_PAYMENT_INTENT',
    setFlavorAddOn = 'SET_FLAVOR_ADD_ON',
    setCupAddOn = 'SET_CUP_ADD_ON',
    setRefillAddOn = 'SET_REFILL_ADD_ON',
    setReceiptOpen = 'SET_RECEIPT_OPEN',
    setShowDropdown = 'SET_SHOW_DROPDOWN',
}

export type CheckoutPayload = {
    [RequestTypes.setPackageType] : {
      packageType: PACKAGE_TYPE 
    };
    [RequestTypes.setDeliveryZip] : {
        deliveryZip: string
    };
    [RequestTypes.setDeliveryDate] : {
        deliveryDate: Date
    };
    [RequestTypes.addFlavorSelection] : {
        flavorItem: FlavorItem
    };
    [RequestTypes.removeFlavorSelection] : {
        flavorItem: FlavorItem
    };
    [RequestTypes.clearFlavorSelection] : {
        flavorItems: []
    };
    [RequestTypes.setCupSelection] : {
        cupSelection: CupSelection
    };
    [RequestTypes.clearCupSelection] : {
        cupSelection: CupSelection
    };
    [RequestTypes.setPaymentIntent]: {
        paymentIntent: PaymentIntent
    };
    [RequestTypes.setFlavorAddOn]: {
        flavorAddOn: Boolean
    };
    [RequestTypes.setCupAddOn]: {
        cupAddOn: Boolean
    };
    [RequestTypes.setRefillAddOn]: {
        refillAddOn: Boolean
    };
    [RequestTypes.setReceiptOpen]: {
        open: Boolean
    };
    [RequestTypes.setShowDropdown]: {
        show: Boolean
    }
}

export type CheckoutActions = ActionMap<CheckoutPayload>[keyof ActionMap<CheckoutPayload>];

export const reducer = (state: CheckoutState, action: CheckoutActions) => {
    const { type, payload } = action;
    
    switch (type) {
        case RequestTypes.setPackageType: 
            return { ...state, packageType: payload.packageType }
        case RequestTypes.setDeliveryZip:
            return { ...state, deliveryZip: payload.deliveryZip }
        case RequestTypes.setDeliveryDate:
            return { ...state, deliveryDate: payload.deliveryDate }
        case RequestTypes.addFlavorSelection:
            return { ...state, flavorSelection: [...state.flavorSelection, payload.flavorItem] }
        case RequestTypes.removeFlavorSelection:
            return { ...state, flavorSelection: filter(state.flavorSelection, (flavor) => JSON.stringify(flavor) !== JSON.stringify(payload.flavorItem)) }
        case RequestTypes.clearFlavorSelection:
            return { ...state, flavorSelection: payload.flavorItems }
        case RequestTypes.setCupSelection: 
            return { ...state, cupSelection: payload.cupSelection }
        case RequestTypes.clearCupSelection:
            return { ...state, cupSelection: payload.cupSelection }
        case RequestTypes.setPaymentIntent:
            return { ...state, paymentIntent: payload.paymentIntent }
        case RequestTypes.setFlavorAddOn:
            return { ...state, flavorAddOn: payload.flavorAddOn }
        case RequestTypes.setCupAddOn:
            return { ...state, cupAddOn: payload.cupAddOn }
        case RequestTypes.setRefillAddOn:
            return { ...state, refillAddOn: payload.refillAddOn }
        case RequestTypes.setReceiptOpen:
            return { ...state, receiptOpen: payload.open }
        case RequestTypes.setShowDropdown:
            return { ...state, showDropdown: payload.show }
        default:
            throw new Error();
    }
};