import { Tab, Tabs } from '@mui/material';
import DynamicReceipt from '../DynamicReceipt';
import { useEffect, useState } from 'react';
import { FlavorItem, FlavorType } from '../../../context/Types';
import { useCheckoutContext } from '../../../context';
import { filter, isMatch } from 'lodash';

type FlavorStepProps = {
    flavors: FlavorItem[],
    flavorTab: number,
    handleFlavorTabChange: (event: React.SyntheticEvent, newValue: number) => void
};

const FlavorOption = ( flavorItem: FlavorItem ) => {
    const { setFlavorAddOn, addFlavorSelection, removeFlavorSelection, flavorSelection } = useCheckoutContext();
    const [isActive, setIsActive] = useState(Boolean);
    const [flavor,] = useState<FlavorItem>(flavorItem);

    const selectFlavorItem = () => {
        if (isActive) {
            setIsActive(false);
            removeFlavorSelection(flavor);
        } else {
            if (flavorSelection.length < 2) {
                setIsActive(true);
                addFlavorSelection(flavor);

                // mark as add on if organic flavors are selected
                if (flavorItem.type === FlavorType.organic) {
                    setFlavorAddOn(true);
                } else {
                    setFlavorAddOn(false);
                }
            }
        }
    }

    useEffect(() => {
        const found = filter(flavorSelection, (item) => isMatch(item, flavorItem))
        setIsActive(found.length > 0)
    }, []);

    return (
        <div id={flavorItem.flavorId} className='flex flex-col justify-center items-center gap-3 w-1/4 bg' >
            <div onClick={selectFlavorItem} className={`rounded-full ${ flavorItem.flavorColor } w-3/5 aspect-square p-1 hover:shadow-lg hover:border-solid hover:border-slate-500 hover:border-[2px]` + (isActive ? ' border-solid border-slate-500 border-[1.5px]': '')}></div>
            <div className='font-serif text-sm lg:text-lg'>{ flavorItem.flavorName }</div>
        </div>
    )
  };


const FlavorSelector = ({ flavors, flavorTab, handleFlavorTabChange } : FlavorStepProps) => {

    return (
        <div className='flex flex-col gap-5 items-center col-start-1 col-span-5 row-start-2 sm:col-start-2 sm:col-span-3 lg:col-start-4 lg:col-span-2 lg:row-start-1 lg:row-span-2 xl:col-start-3 border-solid border-slate-400 border-[1px] lg:border-l-0 lg:rounded-xl lg:rounded-l-none p-10'>
            <div className='font-rubik text-2xl'>Choose your flavors!</div>
            <div className='font-serif text-lg text-center'>Pleast select two of the following flavors</div>
            <Tabs value={ flavorTab } onChange={ handleFlavorTabChange } aria-label="basic tabs example">
                <Tab label="Standard" />
                <Tab label="Organic" />
            </Tabs>
            <div id='flavors' className='flex flex-wrap gap-5 gap-y-8 justify-center items-center w-full mt-5'>
                { flavors.map(({ flavorId, type, flavorName, flavorColor}) => <FlavorOption key={ flavorId } flavorId={ flavorId } flavorColor={ flavorColor } flavorName={ flavorName } type={ type }/>)}
            </div>
        </div>
    );
}

const FlavorStep = ({ flavors, flavorTab, handleFlavorTabChange } : FlavorStepProps) => {
    return (
    <>
        <FlavorSelector flavors={ flavors} flavorTab={ flavorTab } handleFlavorTabChange={ handleFlavorTabChange }/>
        <DynamicReceipt />
    </>
    );
}

export default FlavorStep;