import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

interface RatingProps {
    stars: number
}

const STAR_COUNT = 5;

const RatingCard = ({ stars }: RatingProps) => {
    return (
        <div className='flex gap-2 absolute left-10 top-10'>
            { Array.from({ length: STAR_COUNT }, (_,i) => <FontAwesomeIcon key={i} icon={faStar} style={ i < stars ? {color: "#FFD43B"} : undefined} />) }
        </div>
    );
}

export default RatingCard;