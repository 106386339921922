const Header = () => {
    return (
        <div id="header-container" className="flex flex-col col-start-2 col-span-1 justify-center items-center gap-2 mt-4">
                <div className="font-merriweather text-2xl md:text-3xl font-normal">Meet The Kings</div>
            </div>
    );
}

export default Header;

