const Bio = () => {
    return (
        <div id="bio-container" className="flex flex-col col-start-2 col-span-1 justify-center items-center gap-10">
            <div className="font-merriweather text-2xl md:text-3xl font-normal">Our Story</div>
            <p className="font-merriweather italic text-base sm:text-xl font-light text-center md:ml-20 md:mr-20 leading-loose">5th St. Slush Kings started when 3 high school friends moved in together and decided that wasn’t enough. So naturally, that manifested into a high end slushie machine rental company. Since high school community has been everything to us and we agree that the best way to foster community is through amazing events. </p>
            <p className="font-merriweather italic text-base sm:text-xl font-light text-center md:ml-20 md:mr-20 leading-loose">The goal of 5th St. Slush Kings is to help you elevate your event and make it unforgettable. Stay frosty. </p>
        </div>
    );
}

export default Bio;

