import { useState, useEffect } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { throttle } from 'lodash';
const tailwindConfig = require("../tailwind.config");

const findKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value)

const getDeviceConfig = (width: any) => {
  const fullConfig = resolveConfig(tailwindConfig)
  const { screens } = fullConfig.theme

  const bpSizes = Object.keys(screens).map((screenSize) =>
    parseInt(screens[screenSize])
  )

  const bpShapes = bpSizes.map((size, index) => ({
    min: !index ? 0 : bpSizes[index - 1],
    max: size,
    key: findKeyByValue(screens, `${size}px`),
  }))

  let breakpoint = '2xl'

  bpShapes.forEach((shape) => {
    if (!shape.min && width < shape.max) {
      breakpoint = shape.key!
    } else if (width >= shape.min && width < shape.max) {
      breakpoint = shape.key!
    } else if (!shape.max && width >= shape.max) {
      breakpoint = shape.key!
    }
  })

  return breakpoint
}

const useTailwindBreakpoint = (): string => {
  const [width] = useState(typeof window !== 'undefined' ? window.innerWidth : 0)
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(width))

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(typeof window !== 'undefined' ? window.innerWidth : 0))
    }, 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [brkPnt])

  return brkPnt
}

export default useTailwindBreakpoint;