import { useEffect, useState } from "react";
import { useCheckoutContext } from "../../../context";
import DynamicReceipt from "../DynamicReceipt";
import { CUP_STYLES, CupSelection } from "../../../context/Types";
import { isEqual } from "lodash";

type CupItemProps = {
    cupStyleId: CUP_STYLES
    name: string,
    imgSrc: string
};

const CUP_OPTIONS: CupItemProps[] = [
    {
        cupStyleId: CUP_STYLES.standard,
        name: 'Standard Cups',
        imgSrc: 'https://picsum.photos/id/16/1400/600'
    },
    {
        cupStyleId: CUP_STYLES.premium,
        name: 'Premium Cups',
        imgSrc: 'https://picsum.photos/id/16/1400/600'
    },
];

const CupItem = ({ cupStyleId, name, imgSrc } : CupItemProps) => {
    const { cupSelection, setCupSelection, setCupAddOn } = useCheckoutContext();
    const [active, setActive] = useState<Boolean>();
    const [cupStyleItem,] = useState<CupSelection>({ cupSelectionId: cupStyleId, name })

    const handleCupSelection = () => {
        setCupSelection(cupStyleItem);
        setActive(!active);

        // mark this as an add on if premium cups
        if (cupStyleItem.cupSelectionId === CUP_STYLES.premium) {
            setCupAddOn(true);
        } else {
            setCupAddOn(false);
        }
    }

    useEffect(() => {
        if (isEqual(cupStyleItem, cupSelection)) {
            setActive(true);
        } else {
            setActive(false);
        }
    })

    return (
        <div className='flex flex-col gap-2 w-3/5'>
            <div className='text-center font-rubik text-lg font-base'>{ name }</div>
            <img className={'aspect-video rounded-md hover:border-solid hover:border-[1.5px] hover:border-slate-600' + (active ? 'border-solid border-[1px] border-slate-800' : '')} src={ imgSrc } onClick={handleCupSelection}/> 
        </div>
    );
};

const CupSelector = () => {
    return (
        <div className='flex flex-col gap-5 items-center col-start-1 col-span-5 row-start-2 sm:col-start-2 sm:col-span-3 lg:col-start-4 lg:col-span-2 lg:row-start-1 lg:row-span-2 xl:col-start-3 border-solid border-slate-400 border-[1px] lg:border-l-0 lg:rounded-xl lg:rounded-l-none p-10'>
            <div className='font-rubik text-2xl'>Choose your Cup Style!</div>
            <div className='font-serif text-lg text-center'>Your selected package includes 100 (8oz) cups or upgrade to premium cups for $25</div>
            <div id='cups' className='flex flex-col gap-7 justify-center items-center w-full mt-5'>
                { CUP_OPTIONS.map(({ cupStyleId, name, imgSrc}) => <CupItem key={ cupStyleId } cupStyleId={ cupStyleId } name={ name } imgSrc={ imgSrc }/>)}
            </div>
        </div>
    );
}

const CupStep = () => {
    return (
        <>
            <CupSelector />
            <DynamicReceipt />
        </>
    );
}

export default CupStep;