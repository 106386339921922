import { Footer, NavBar } from "../../components";
import { ProductCard } from "./components";
import { Package } from '../../types/types';
import { Divider } from "../../components";
import { useLayoutEffect } from "react";
import SingleTankPackage from '../../assets/SingleTankPackage.jpg';
import DoubleTankPackage from '../../assets/DoubleTankPackage.png';
import { Card } from "@mui/material";

const testPackages: Package[] = [
    {
        packageName: 'Single Tank',
        packageId: 'single-tank',
        imgSrc: '',
        machineType: 'Single tank',
        drinkCount: 50,
        startingPrice: 200
    },
    {
        packageName: 'Double Tank',
        packageId: 'double-tank',
        imgSrc: '',
        machineType: 'Dual tank',
        drinkCount: 100,
        startingPrice: 350
    },
]
const singlePackage = testPackages[0];
const doublePackage = testPackages[1];

const Products = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const CardContainer = () => {
        return (
            <div className='col-start-1 col-span-3 flex flex-col md:flex-row gap-10 justify-center mt-10'>
                <ProductCard 
                    packageName={ singlePackage.packageName }
                    packageId={ singlePackage.packageId }
                    imgSrc={ SingleTankPackage }
                    machineType={ singlePackage.machineType }
                    drinkCount={ singlePackage.drinkCount }
                    startingPrice={ singlePackage.startingPrice }
                />
                <ProductCard 
                    packageName={ doublePackage.packageName }
                    packageId={ doublePackage.packageId }
                    imgSrc={ DoubleTankPackage }
                    machineType={ doublePackage.machineType }
                    drinkCount={ doublePackage.drinkCount }
                    startingPrice={ doublePackage.startingPrice }
                />
            </div>
        )
    }

    return (
        <>
            <NavBar />
            <div className="grid grid-cols-home mt-24">
                <div id="header-container" className="flex flex-col col-start-2 col-span-1 justify-center items-center mt-10">
                    <div className="font-merriweather text-3xl md:text-3xl font-normal">Our Packages</div>
                </div>
                <CardContainer />
                <Footer />
            </div>
        </>
    );
}

export default Products;