import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { PackageCard, Chevron } from "../../../components";
import DoubleTank from '../../../assets/DoubleTank.png'
import SingleTank from '../../../assets/SingleTank.png'

interface PackageCardProps {
    imgSrc: string;
    packageId: string;
    packageName: string;
    description: string;
}

const testPackageData: PackageCardProps[] = [
    {
        imgSrc: SingleTank,
        packageId: 'single-tank',
        packageName: 'Single Tank',
        description: 'The single tank package is perfect for any small to mid-sized occasion. \
        Our commercial, single tank machines can serve up to 50 (8oz) drinks with a single fill. \
        Whether your event is for a few very thirsty folks or for a larger, more causal get together, we\'ve got you covered!'
    },
    {
        imgSrc: DoubleTank,
        packageId: 'double-tank',
        packageName: 'Double Tank',
        description: 'The double tank package is built to do it all. \
        Our commercial, double tank machines produce up to 100 (8oz) drinks per fill and freeze in just over an hour. \
        Regardless of the nature of your event, this baby should have no problem keeping up with the crowd.'
    },
]

const Packages = () => {
    const navigate = useNavigate();
    const myRef = useRef<HTMLDivElement>(null);

    const getScrollInterval = (direction: string) => {
        const directionMultiplier = direction === 'left' ? -1 : 1
        return window.innerWidth * directionMultiplier;
    }

    const scrollLeft = () => {
        myRef.current?.scrollBy({ left: getScrollInterval('left'), top: 0, behavior: "smooth"});
    }

    const scrollRight = () => {
        myRef.current?.scrollBy({ left: getScrollInterval('right'), top: 0, behavior: "smooth"});
    }

    return (
        <div className='col-start-1 col-span-3'>
            <div className='col-start-2 col-span-1 text-center font-merriweather text-xl xs:text-2xl md:text-4xl font-base md:font-normal mb-[2vh] text-sky-950'>Our Packages</div>
            <div ref={myRef} id='package-container' className='flex gap-mobile-package justify-start md:justify-center md:gap-desk-package items-center overflow-auto pl-package pr-package md:pl-0 md:pr-0 no-scrollbar pt-14 pb-14 bg-slate-200'>
                <Chevron 
                    direction='left'
                    clickHandler={() => scrollLeft}
                    hidden='md:hidden'
                />
                { testPackageData.map(({ imgSrc, packageId, packageName, description }) => { return (
                        <PackageCard
                            key={ packageId }
                            imgSrc={ imgSrc }
                            name={ packageName }
                            description={ description }
                            callBack={ () => navigate(`/products/${ packageId }`) }
                        />
                    );
                })}
                <Chevron 
                    direction='right'
                    clickHandler={() => scrollRight}
                    hidden='md:hidden'
                />
            </div>
        </div>
    );
};

export default Packages;