import { useLayoutEffect } from "react";
import { NavBar, Divider, Footer} from "../../components";
import { 
    Header,
    Kingspic,
    Bio,
    Our_Team
} from './Components';


const AboutUs = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar /> 
            <div className="grid gap-y-8 mt-28 grid-cols-home">
                <Header/>
                <Kingspic/>
                <Divider/> 
                <Bio/>
                <Footer />
            </div>       
        </>
    );
}

export default AboutUs;