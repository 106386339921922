import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

interface ChevronProps {
    direction: string;
    clickHandler: Function;
    hidden?: string;
}

const Chevron = ({ direction, clickHandler, hidden }: ChevronProps) => {
    const alignment = direction === 'left' ? 'left-0' : 'right-0';
    return (
        <div 
            className={`flex justify-center items-center absolute w-9 h-20 ${ hidden } ${alignment} border-solid border-slate-400 border-[1px] shadow-md bg-white z-10`}
            onClick={clickHandler()}
        >
            {direction === 'left' 
                ? <FontAwesomeIcon icon={faChevronLeft} size='2x' />
                : <FontAwesomeIcon icon={faChevronRight} size='2x' />
            }
        </div>
    );
};

export default Chevron;