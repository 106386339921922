/*
    TODO: Still need to refactor to add the "Most Popular" to the container so that we can promote whichever package we want
*/

import { Button } from "../buttons";

interface PackageCardProps {
    imgSrc: string;
    name: string;
    description: string;
    callBack: Function;
};

const PackageCard = ({
    imgSrc,
    name,
    description,
    callBack
}: PackageCardProps) => {
    return (
        <div className="flex bg-sky-950 p-4 rounded-md w-package lg:w-[450px] h-[650px] md:h-[680px] flex-shrink-0">
            <div id='card' className='flex flex-col w-full justify-start items-center gap-2 md:gap-4 shadow-lg bg-white border-solid border-[2px] border-orange-100'>
                <div className='flex justify-center w-4/5 h-1/2 rounded-t-md'>
                    <img src={imgSrc} alt='package-shot' className="object-fit h-full"></img>
                </div>
                <div className='font-merriweather text-lg md:text-2xl font-md'>{ name }</div>
                <div className='font-merriweather text-md text-slate-500 text-center items-stretch'>{ description }</div>
                <div id='button-container' className='mt-auto mb-6 min-h-0'>
                    <Button
                        label='View Package Details'
                        color='bg-cyan-700'
                        textColor='text-white'
                        height='h-10'
                        callBack={callBack}
                    />
                </div>
            </div>
        </div>
    );
};

export default PackageCard;