import RatingCard from "./RatingCard";

interface TestimonialProps {
    stars: number;
    review: string;
    reviewerName: string;
}

const TestimonialCard = ({ stars, review, reviewerName}: TestimonialProps) => {
    return (
        <div className='relative flex flex-col flex-wrap items-start h-[320px] rounded-md border-solid border-slate-400 border-[1px] bg-white w-testimonial  p-10 flex-shrink-0 shadow-lg pt-20'>
            <RatingCard stars={ stars } />
            <div className='text-center font-sans italic'>{ `"${ review }"` }</div>
            <div className=' self-end text-right mt-auto'>{ `- ${ reviewerName }` }</div>
        </div>
    );
};

export default TestimonialCard;