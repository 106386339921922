export enum PACKAGE_TYPE {
    'single' = 'SINGLE_TANK',
    'double' = 'DOUBLE_TANK'
};

export enum CUP_STYLES {
    standard = 'STANDARD',
    premium = 'PREMIUM'
}

export enum FlavorType {
    'standard' = 'STANDARD',
    'organic' = 'ORGANIC'
}

export type PaymentIntent = {
    paymentIntentId: string;
    clientSecret: string;
}

export type CheckoutState = {
    packageType: PACKAGE_TYPE,
    deliveryZip: string,
    deliveryDate: Date,
    flavorSelection: FlavorItem[],
    cupSelection: CupSelection,
    paymentIntent: PaymentIntent | null,
    flavorAddOn: Boolean,
    cupAddOn: Boolean,
    refillAddOn: Boolean,
    receiptOpen: Boolean,
    showDropdown: Boolean
}

export type FlavorItem = {
    flavorId: string,
    type: FlavorType
    flavorName: string,
    flavorColor: string,
}

export type CupSelection = {
    cupSelectionId: CUP_STYLES
    name: string
}