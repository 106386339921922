import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Footer, NavBar } from "../../components";
import { FlavorStep } from "./flavor-step";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlavorItem, FlavorType } from "../../context/Types";
import { useCheckoutContext } from "../../context";
import { CupStep } from "./cup-selector";
import PaymentStep from "./checkout/Payment";

enum Steps {
    flavorSelection = 'FLAVOR_SELECTION',
    cupSelection = 'CUP-SELECTION',
    refillSelection = 'REFILL-SELECTION',
    payment = 'PAYMENT'
};
type CheckoutStep = {
    stepIndex: number,
    stepName: Steps
};
type NavProps = {
    navigateForward : () => void,
    navigateBackward: () => void,
    renderNextButton: Boolean
}

const standardFlavors : FlavorItem[] = [
    { 
        'flavorId': 'flavor-lime-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Lime',
        'flavorColor': 'bg-green-400'
    },
    { 
        'flavorId': 'flavor-cherry-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Cherry',
        'flavorColor': 'bg-red-500'
    }, 
    { 
        'flavorId': 'flavor-pino-colada-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Pino Colada',
        'flavorColor': 'bg-yellow-300'
    }, 
    { 
        'flavorId': 'flavor-grapefruit-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Grapefruit',
        'flavorColor': 'bg-pink-400'
    }, 
    { 
        'flavorId': 'flavor-cola-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Cola',
        'flavorColor': 'bg-amber-900'
    },
    { 
        'flavorId': 'flavor-blue-raz-1234556',
        'type': FlavorType.standard,
        'flavorName': 'Blue Raz',
        'flavorColor': 'bg-blue-400'
    }
];

const organicFlavors : FlavorItem[] = [
    { 
        'flavorId': 'flavor-lime-organic-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Lime',
        'flavorColor': 'bg-green-200'
    },
    { 
        'flavorId': 'flavor-cherry-orgainc-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Cherry',
        'flavorColor': 'bg-red-300'
    }, 
    { 
        'flavorId': 'flavor-pino-colada-orgainc-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Pino Colada',
        'flavorColor': 'bg-yellow-200'
    }, 
    { 
        'flavorId': 'flavor-grapefruit-organic-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Grapefruit',
        'flavorColor': 'bg-pink-400'
    }, 
    { 
        'flavorId': 'flavor-cola-organic-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Cola',
        'flavorColor': 'bg-amber-700'
    },
    { 
        'flavorId': 'flavor-blue-raz-organic-1234556',
        'type': FlavorType.organic,
        'flavorName': 'Blue Raz',
        'flavorColor': 'bg-blue-200'
    }
];

const CHECKOUT_STEPS : Record<number, CheckoutStep>= {
    0: {
        stepIndex: 0, stepName: Steps.flavorSelection
    },
    1: {
        stepIndex: 1, stepName: Steps.cupSelection
    },
    2: {
        stepIndex: 2, stepName: Steps.refillSelection
    },
    3: {
        stepIndex: 3, stepName: Steps.payment
    }
};

export const NavButtons = ({ navigateForward, navigateBackward, renderNextButton} : NavProps) => {
    return (
        <div id='button-container' className='col-start-1 col-span-5 lg:col-start-2 lg:col-span-3 row-start-3 flex justify-center items-center gap-[15vw]'>
                <Button
                    label={<div className='flex gap-5'><FontAwesomeIcon icon={faChevronLeft} size="lg" /> Back</div>}
                    color='bg-red-700'
                    textColor='text-white'
                    height='h-15'
                    callBack={navigateBackward}
                    styles='w-1/3 md:w-1/4 lg:w-1/6'
                />
                { renderNextButton ? 
                    <Button
                        label={<div className='flex gap-5'>Continue <FontAwesomeIcon icon={faChevronRight} size="lg" /></div>}
                        color='bg-red-700'
                        textColor='text-white'
                        height='h-15'
                        callBack={navigateForward}
                        styles='w-1/3 md:w-1/4 lg:w-1/6'
                    /> : 
                    <></>
                }
        </div>
    );
};

const PackageBuilder = () => {
    const navigate = useNavigate();
    const { clearFlavorSelection } = useCheckoutContext();
    const [{ stepIndex, stepName}, setCurrentStep] = useState(CHECKOUT_STEPS[0]);
    const [flavorTab, setFlavorTab] = useState<number>(0);
    const [flavors, setFlavors] = useState<FlavorItem[]>(standardFlavors);

    const handleFlavorTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setFlavorTab(newValue);
        setFlavors(flavorTab ? standardFlavors : organicFlavors);
        clearFlavorSelection();
    }
    
    const navigateForward = () => {
        setCurrentStep(CHECKOUT_STEPS[stepIndex + 1]);
    };

    const navigateBackward = () => {
        if (stepIndex > 0) {
            setCurrentStep(CHECKOUT_STEPS[stepIndex - 1]);
        } else {
            navigate('/products/823498')
        }
    };

    // Get the current step element based on the current stepName in state
    const getStep = () => {
        switch(stepName) {
            case Steps.flavorSelection:
                return <FlavorStep flavors={ flavors } flavorTab={ flavorTab } handleFlavorTabChange={ handleFlavorTabChange } />
            case Steps.cupSelection:
                return <CupStep />
            case Steps.refillSelection:
                return <div>Refill Selection Step</div>
            case Steps.payment:
                return <PaymentStep />
            default:
                <></>
        }
    }


    return (
        <>
            <NavBar />
            <div id='package-builder' className='grid grid-cols-package-builder grid-rows-package-builder gap-y-10 lg:mt-20'>
                { getStep() }
                <NavButtons navigateForward={navigateForward} navigateBackward={navigateBackward} renderNextButton={ stepName === Steps.payment ? false : true}/>
            </div>
            <Footer />
        </>
    );
}

export default PackageBuilder;