import { useCallback, useLayoutEffect } from "react";
import { useTailwindBreakpoint } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Divider } from "@mui/material";
import { useCheckoutContext } from "../../context";
import { CUP_STYLES, FlavorType } from "../../context/Types";

const collapsibleScreenSizes = ['xs', 'sm', 'md', 'lg'];

const DynamicReceipt = () => {
    const breakpoint = useTailwindBreakpoint();
    const { receiptOpen, showDropdown, setShowDropdown, setReceiptOpen } = useCheckoutContext();

    const ReceiptDropdown = useCallback(() => {
        return (
            <div className={'bg-black h-14 flex justify-center shadow-lg sm:mt-3'} onClick={() => setReceiptOpen(!receiptOpen)}>
                <div className='flex justify-center items-center text-white font-rubik text-xl gap-5'>
                    { receiptOpen ? 
                    <>
                        <p>Close Receipt</p>
                        <FontAwesomeIcon icon={ faChevronUp } />
                    </> :
                    <>
                        <p>View Order Total</p>
                        <FontAwesomeIcon icon={ faChevronDown } />
                    </>

                    }
                </div>
            </div>
        );
    }, [receiptOpen]);

    const FlavorSubtotal = () => {
        const { flavorSelection } = useCheckoutContext();

        return (
            flavorSelection.length > 0 ? 
            <div className='flex flex-col items-start gap-3 w-4/5'>
                <div className='font-rubik text-md md:text-lg'>{ flavorSelection[0].type === FlavorType.standard ? 'Standard Mix - included' : 'Organic Mix - $49'}</div>
                { flavorSelection.map(({ flavorName, flavorId }) => <div key={ flavorId } className='ml-10 font-serif'>• { flavorName } (2 bottles)</div>)}
            </div> : <></>
        );
    }

    const CupSubtotal = () => {
        const { cupSelection } = useCheckoutContext();

        return (
            <div className='flex flex-col items-start gap-3 w-4/5'>
                <div className='font-rubik text-md md:text-lg'>{ cupSelection.cupSelectionId === CUP_STYLES.standard ? 'Standard Cups - included' : 'Premium Cups - $25'}</div>
            </div>
        );
    };

    const IncludedServices = () => {
        return (
            <div className='flex flex-col items-start gap-2 w-4/5 mt-10'>
                <div className='font-rubik text-md md:text-lg'>Serving Cart - FREE</div>
                <div className='font-rubik text-md md:text-lg'>Delivery -  FREE</div>
            </div>
        )
    }

    const Receipt = () => {
        return (
            <>
                { showDropdown ? <ReceiptDropdown /> : <></> }
                <div className='flex flex-col gap-5 lg:gap-8 items-center bg-palePink lg:h-full border-solid border-slate-400 border-[1px] lg:rounded-xl lg:rounded-r-none p-3 lg:p-10'>
                    <div className='font-rubik text-2xl lg:text-4xl lg:font-thin'>Order Summary</div>
                    <Divider variant="fullWidth" style={{width:'80%'}} />
                    <FlavorSubtotal />
                    <CupSubtotal />
                    <IncludedServices />
                </div>
            </>
        );
    };

    // This useEffect only runs on breakpoint changes and renders the closed receipt when we hit a md or smaller screen
    useLayoutEffect(() => {
        const isSmallScreen = collapsibleScreenSizes.includes(breakpoint)
        setShowDropdown(isSmallScreen)
        setReceiptOpen(!isSmallScreen)
    }, [breakpoint])


    return (
        <div
            className='col-start-1 col-span-5 row-start-1 row-span-1 sm:col-start-2 sm:col-span-3 lg:col-start-1 lg:col-span-3 lg:row-span-2 xl:col-start-2 xl:col-span-1'
        >
            { receiptOpen ? <Receipt /> : <ReceiptDropdown />}
        </div>
    );
}

export default DynamicReceipt;