import { useLayoutEffect } from 'react';
import { NavBar, Footer } from '../../components';
import { 
  Packages,
  Testimonials,
} from './components';
import { Header } from './components/Header';

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
      <>
        <NavBar />
        <div className="grid gap-y-20 mt-24 grid-cols-home">
          <Header />
          <Packages />
          <Testimonials />
          <Footer />
        </div>
      </>
  )
};

export default Home;